const dropdown = document.querySelectorAll('.dropdown-button')

function toggleButton(el) {
    if (el.classList.contains('active')) {
        el.classList.remove('active')
    } else {
        el.classList.add('active')
    }   
}

dropdown.forEach(function (el) {
    el.addEventListener('click', function () {
        toggleButton(el)
     });
});