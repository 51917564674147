
import { showHide } from './../helpers/transition'
import throttle from './../helpers/throttle'


const bg = (beforeNode = null) => {
	const div = document.createElement('div')
	div.classList.add('js-menu-background')
	div.style.opacity = 0
	div.style.pointerEvents = 'none'
	return beforeNode.parentNode.insertBefore(div, beforeNode)
}


const createMenu = (menu, menuButton) => {

	const backgroundEl = bg(menu)
	const showHideMenu = showHide(menu, 'is-open')
	const html = document.documentElement

	// set initial states
	menuButton.setAttribute('aria-expanded', false)
	menu.setAttribute('aria-hidden', true)

	const open = (event) => {
		backgroundEl.style.display = 'block'
		backgroundEl.getBoundingClientRect()
		backgroundEl.style.opacity = 1
		backgroundEl.style.pointerEvents = ''
		html.classList.add('js-menu-noscroll')

		showHideMenu.show(() => {
			backgroundEl.style.display = 'block'
		})
		menuButton.blur()
		menuButton.setAttribute('aria-expanded', true)
		menu.setAttribute('aria-hidden', false)
		menuButton.classList.add('is-open')
	}

	const close = (event) => {
		backgroundEl.style.pointerEvents = 'none'
		backgroundEl.style.opacity = 0
		html.classList.remove('js-menu-noscroll')

		showHideMenu.hide(() => {
			backgroundEl.style.display = 'none'
		})
		menuButton.blur()
		menuButton.setAttribute('aria-expanded', false)
		menu.setAttribute('aria-hidden', true)
		menuButton.classList.remove('is-open') 
	}

	backgroundEl.addEventListener('click', close)

	const clickHandler = (event) => showHideMenu.isShown() ? close(event) : open(event)
	menuButton.addEventListener('click', clickHandler)

	const escHandler = (event) => {
		const isEsc = (event.key === 'Escape' || event.code === 'Escape' || event.keyCode === 27)
		if (isEsc) close()
	}
	window.addEventListener('keydown', escHandler, true);
}


const menuButtonScroll = (menuButton) => {

	const scrollHandler = (event) => {
		const offset = window.scrollY || window.pageYOffset || 0
		if (offset > 50) {
			menuButton.classList.add('button--shadow')
		} else {
			menuButton.classList.remove('button--shadow')
		}
	}

	window.addEventListener('scroll', throttle(scrollHandler, 50))
}

export function init(menu, menuButton) {
	createMenu(menu, menuButton)
	menuButtonScroll(menuButton)
}
