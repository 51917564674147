

export const duration = (el) => {
	const style = getComputedStyle(el)
	if (
		!style ||
		!style.transitionDuration ||
		!parseFloat(style.transitionDuration)
	) return false
	return style.transitionDuration
}

export const after = (el, callback = () => {}) => {

	const handler = (event) => {
		// stop events bubbling up to other elements
		event && event.stopPropagation()
		// make sure we're only firing on the current elem's event and not its children
		if (event && el !== event.target) return false
		event && el.removeEventListener(event.type, handler)
		callback(event)
	}

	el.addEventListener('transitionend', handler, false)

	// run straight away if there's no transition duration
	if (!duration(el)) handler()
}

export const showHide = (el, toggleClass = 'is-shown') => {

	let isShown = false

	function animate(bool, callback = () => {}) {
		isShown = bool
		el.style.display = 'block'
		el.getBoundingClientRect()
		after(el, () => {
			el.style.display = ''
			callback(isShown)
		})
		isShown ? el.classList.add(toggleClass) : el.classList.remove(toggleClass)
	}

	const show = (cb) => animate(true, cb)
	const hide = (cb) => animate(false, cb)
	const toggle = (cb) => isShown ? hide(cb) : show(cb)

	return {
		show, hide, toggle, isShown : () => isShown
	}
}