
/*
	adds basic validation to fields
	add .js-validate to single <input> fields for basic validation
*/


const element = (html = '') => {
	const container = document.createElement('div')
	container.innerHTML = html.trim()
	return container.firstChild
}


const error = messages => {
	const message = (messages.length === 1) 
		? `<p>${ messages[0] }</p>`
		: `<ul>${ messages.map(m => `<li>${m}</li>`).join('') }</ul>`

	const html = `
		<div class="message error">
			${message}
		</div>
	`
	return element(html)
}


const messages = input => {
	const messages = []
	if (!input.checkValidity()) messages.push(input.validationMessage)
	return messages.filter(Boolean)
}


const showErrors = (input = null, messages = []) => {
	input.classList.remove('error')
	input.previousElementSibling.classList && input.previousElementSibling.classList.contains('message') && input.previousElementSibling.remove()
	if (messages.length) {
		input.classList.add('error')
		const errors = error(messages)
		input.parentNode.insertBefore(errors, input)
	}
}


// event handlers


const validate = input => showErrors(input, messages(input))

const onFocus = e => {
	e.currentTarget.previousVal = e.currentTarget.value
}

const onBlur = e => {
	const input = e.currentTarget;
	
	// if the value hasn't changed, do nothing
	if (input.value === input.previousVal) return

	// only validate when there's a value, otherwise fields tend to jump around awkwardly
	// when someone focuses/blurs without filling in any value
	input.value ? showErrors(input, messages(input)) : showErrors(input, [])
}


const validateSet = el => {
	const inputs = [...el.querySelectorAll('input')]
	console.log(inputs)
}


/////////////////////

export const init = form => {
	const context = form instanceof Element ? form : document;
	
	// [...context.querySelectorAll('.js-validate-form')].map(form => {
	// 	console.log(form)
	// 	const inputs = form.querySelectorAll('.js-validate')
	// 	const onsubmit = e => {
	// 		e.preventDefault()
	// 		console.log('test');
	// 		inputs.map(validate)
	// 	}
	// 	form.addEventListener('submit', onsubmit)
	// });

	const firstError = context.querySelector('.error')
	if (firstError) window.scrollTo(0, firstError.getBoundingClientRect().top - 100);

	[...context.querySelectorAll('.js-validate')].map(el => el.addEventListener('blur', onBlur));
	[...context.querySelectorAll('.js-validate')].map(el => el.addEventListener('focus', onFocus));
	[...context.querySelectorAll('.js-validate-set')].map(validateSet)
}



